import * as yup from "yup";
import { useFormik } from "formik";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import endpoints from "../../constants/endpoints";
import MuiTextField from "../../components/MuiTextField";
import { Typography, Grid } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useAuth } from "../../context/UserContext";
import { isSignin } from "../../helpers/util";
import ToastConfig from "../../components/toast/toast";
import paths from "../../routing/paths";
import { Loading } from "../../components/icons/Loading";
const SignIn = (props) => {
  const matches = useMediaQuery(
    (_theme) => _theme?.breakpoints?.down("sm") ?? "600"
  );

  const { UpdateUser } = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const SignInState = isSignin();
  const history = useHistory();
  const validationSchema = yup.object({
    Email: yup
      .string("Introduceți adresa dvs. de email")
      .email("Introduceți o adresă de email validă")
      .required("E-mailul este obligatoriu"),
    Password: yup.string("introduceți parola").required("Parola este necesara"),
  });
  const formik = useFormik({
    initialValues: {
      Email: "",
      Password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      postData(values);
    },
  });

  function postData(values) {
    const myHeaders = new Headers({
      "Content-Type": "application/json",
    });
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(values, null, 2),
      redirect: "follow",
    };

    setIsLoading(true);
    fetch(endpoints.AUTH.SIGNIN, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          UpdateUser(result?.data);
          history.push(paths.USER_LIST);
        }else{
          setIsLoading(false);
          ToastConfig.error(result?.data);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
        setIsLoading(false);
        ToastConfig.error("Signin failed");
      });
  }

  useEffect(() => {
    if (SignInState) {
      props.history.push(paths.USER_LIST);
    }
  }, [props.history,SignInState]);
  return (
    <>
      <form autoComplete="off" onSubmit={formik.handleSubmit}>
        <h2 style={{ textAlign: "center" }}>Log in</h2>
        <Card
          style={{
            display: "flex",
            flexWrap: "wrap",
            width: matches ? "" : "20vw",
            padding: 40,
            margin: "auto",
          }}
        >
          <CardContent>
            <Grid
              container
              spacing={4}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12}>
                <Grid container justifyContent="center" alignItems="center">
                  <Grid item>
                    <Typography variant="h4">
                      {" "}
                      Welcome to the Harness platform
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item xs={12}>
                    <Typography>Email</Typography>
                    <MuiTextField
                      id="Email"
                      value={formik.values.Email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.Email && Boolean(formik.errors.Email)
                      }
                      helperText={formik.touched.Email && formik.errors.Email}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>Password</Typography>
                    <MuiTextField
                      id="Password"
                      type="password"
                      isProtected="true"
                      value={formik.values.Password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.Password &&
                        Boolean(formik.errors.Password)
                      }
                      helperText={
                        formik.touched.Password && formik.errors.Password
                      }
                    />
                  </Grid>
                  <Grid item xs={12} display={"none"}>
                    <Typography style={{ textAlign: "right" }}>
                      I forgot my password
                    </Typography>{" "}
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                {!isLoading ? (
                  <Grid container justifyContent="center" alignItems="center">
                    <Grid item>
                      <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        type="submit"
                      >
                        Sign in
                      </Button>
                    </Grid>
                  </Grid>
                ) : (
                  <Loading />
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </form>
    </>
  );
};

export default SignIn;
